import { Stack } from '@mui/material';
import { AppState } from 'index';
import { fetchGenres, fetchInstruments, fetchKeyTags, fetchTonalities, fetchFormNames } from 'api/tags.api';
import React, { useContext, useEffect } from 'react'
import { TagStr } from 'types/tags.types';
import { RithmSection } from 'components/forms/rithmSectionSettings/RithmSection';
import { SynthSection } from 'components/forms/synthSettings/SynthSection';
import { BassSettingsForm } from 'components/forms/bassSettingsForm/BassSettingsForm';
import { PercussionSection } from 'components/forms/percussionSettingsForm/PercussionSection';
import { Midi2WavSection } from 'components/forms/midi2wavForm/Midi2WavSection';

export function SelectPage() {
  const state = useContext(AppState);

  const [genres, setGenres] = React.useState<TagStr[]>([]);

  const fetchAllTags = async () => {
    try {
      const [keys, tonalities, instruments, genres, form_names] = await Promise.all([
        fetchKeyTags(),
        fetchTonalities(),
        fetchInstruments(),
        fetchGenres(),
        fetchFormNames(),
      ]);

      setGenres(genres || []);

      state.keyTags.value = keys || [];
      state.tonalityTags.value = tonalities || [];
      state.instrumentTags.value = instruments || [];
      state.genreTags.value = genres || [];
      state.formNames.value = form_names || [];

    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    fetchAllTags();
  }, [])

  return (
    <Stack direction={"row"} sx={{py: 5}} alignItems={"center"} justifyContent="center">
      <Stack direction={"row"} spacing={5} sx={{width: "100%"}} alignItems={"start"} justifyContent="center">
        <Stack direction={"column"} spacing={3} sx={{width: "100%"}}>
          <Midi2WavSection />
          <RithmSection />
        </Stack>
        <Stack direction={"column"} spacing={3}>
          <SynthSection />
          <BassSettingsForm />
          <PercussionSection />
        </Stack>
      </Stack>
    </Stack>
  )
}
