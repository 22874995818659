import { TagStr } from "types/tags.types";


export const BASS_PRESETS_DATA: readonly TagStr[] = [
    { id: 1, name: "complex_bass"},
    { id: 2, name: "middle_bass"},
    { id: 3, name: "simple_bass"},
    { id: 4, name: "A.bass"},
    { id: 5, name: "B.bass"},
    { id: 6, name: "C.bass"},
    { id: 7, name: "D.bass"},
    { id: 8, name: "E.bass"},
    { id: 9, name: "X.bass"},
    { id: 10, name: "subbass"},
    { id: 11, name: "FL"},
    { id: 12, name: "SEP"},
    { id: 13, name: "SEP_ADD"},
    { id: 14, name: "HFY"},
    { id: 15, name: "TMH"},
    { id: 16, name: "SEP_BASS_MAIN"},
    { id: 17, name: "SEP_BASS_MAIN_ADD"},
]

export const keys: readonly TagStr[] = [
    { id: 0, name: "Cmaj"},
    { id: 1, name: "C#maj" },
    { id: 2, name: "Dmaj" },
    { id: 3, name: "D#maj" },
    { id: 4, name: "Emaj" },
    { id: 5, name: "Fmaj" },
    { id: 6, name: "F#maj" },
    { id: 7, name: "Gmaj" },
    { id: 8, name: "G#maj" },
    { id: 9, name: "Amaj" },
    { id: 10, name: "A#maj" },
    { id: 11, name: "Hmaj" }
]

export const SYNTH_PRESETS_DATA: readonly TagStr[] = [
    { id: 1, name: "small_synth_1" },
    { id: 2, name: "small_synth_2" },
    { id: 3, name: "A" },
    { id: 4, name: "B" },
    { id: 5, name: "C" },
    { id: 6, name: "D" },
    { id: 7, name: "E" },
    { id: 8, name: "chromatic_synth_middle"},
    { id: 9, name: "chromatic_synth_complex"},
    { id: 10, name: "chromatic_synth_big"},
    { id: 11, name: "X"},
    { id: 12, name: "PR"},
    { id: 13, name: "SEP"},
    { id: 14, name: "SEP_ADD"},
    { id: 15, name: "HFY"},
    { id: 16, name: "TMH"},
    { id: 17, name: "JB"},
    { id: 18, name: "SEP_LEAD_1"},
    { id: 19, name: "SEP_LEAD_234"},
    { id: 20, name: "SEP_LEAD_5"},
    { id: 21, name: "SEP_LEAD_6"},
    { id: 22, name: "SEP_BASS_MAIN"},
    { id: 23, name: "SEP_BASS_MAIN_ADD"},
    { id: 24, name: "FL_LEAD_1"},
    { id: 25, name: "FL_LEAD_3"},
]

export const PERCUSSION_PRESETS: readonly TagStr[] = [
    { id: 1, name: "shaker" },
    { id: 2, name: "cowbell" },
    { id: 3, name: "bongo" },
    { id: 4, name: "ride" },
    { id: 5, name: "hi_hat" },
    { id: 6, name: "stick" },
    { id: 7, name: "woodblock" },
    { id: 8, name: "hi_conga" },
    { id: 9, name: "low_tom" },
    { id: 10, name: "add_hat" },
]


