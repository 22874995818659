import axios, { AxiosResponse } from "axios";
import { TagStr } from "types/tags.types";

const url = `${process.env.REACT_APP_BACKEND_API_URL}/tags`;

export async function fetchKeyTags(): Promise<TagStr[] | undefined> {
    try {
        const response: AxiosResponse<TagStr[]> = await axios.get(`${url}/keys`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export async function fetchTonalities() {
    try {
        const response: AxiosResponse<TagStr[]> = await axios.get(`${url}/tonalities`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export async function fetchInstruments() {
    try {
        const response: AxiosResponse<TagStr[]> = await axios.get(`${url}/instruments`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export async function fetchGenres() {
    try {
        const response: AxiosResponse<TagStr[]> = await axios.get(`${url}/genres`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}


export async function fetchFormNames() {
    try {
        const response: AxiosResponse<string[]> = await axios.get(`${url}/forms`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}